function bnReplacer(k, v) {
  if (typeof v === "bigint") {
    return v.toString() + "n";
  }
  return v;
}
function bnReviver(k, v) {
  if (typeof v === "string" && (v.startsWith("-") ? !isNaN(parseInt(v.charAt(1))) : !isNaN(parseInt(v.charAt(0)))) && v.charAt(v.length - 1) === "n") {
    return BigInt(v.slice(0, -1));
  }
  return v;
}

function bigIntIDToXY(position) {
  const bn = BigInt(position);
  const x = Number(BigInt.asIntN(32, bn));
  const y = Number(BigInt.asIntN(32, bn >> 32n));
  return { x, y };
}
function xyToBigIntID(x, y) {
  const bn = (x < 0 ? 2n ** 32n + BigInt(x) : BigInt(x)) + ((y < 0 ? 2n ** 32n + BigInt(y) : BigInt(y)) << 32n);
  return bn;
}

const AREA_SIZE = 11;
const AREA_OFFSET = 5;
function areaCoord(a) {
  if (a >= 0) {
    return Math.floor((a + AREA_OFFSET) / AREA_SIZE);
  } else {
    return -Math.floor((-a + AREA_OFFSET) / AREA_SIZE);
  }
}
function areaLocalCoord(x) {
  return x - (areaCoord(x) * AREA_SIZE - AREA_OFFSET);
}
function wallAt(walls, x, y) {
  const xx = areaLocalCoord(x);
  const yy = areaLocalCoord(y);
  const i = yy * AREA_SIZE + xx;
  return walls[i];
}

export { AREA_OFFSET, AREA_SIZE, areaCoord, areaLocalCoord, bigIntIDToXY, bnReplacer, bnReviver, wallAt, xyToBigIntID };
