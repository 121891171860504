import { fromJSProcessor } from 'ethereum-indexer-js-processor';
import { bigIntIDToXY } from 'template-game-common';

var ControllerType = /* @__PURE__ */ ((ControllerType2) => {
  ControllerType2[ControllerType2["None"] = 0] = "None";
  ControllerType2[ControllerType2["Basic"] = 1] = "Basic";
  ControllerType2[ControllerType2["Owner"] = 2] = "Owner";
  return ControllerType2;
})(ControllerType || {});
const GameIndexerProcessor = {
  // version is automatically populated via version.cjs to let the browser knows to reindex on changes
  // only works if the changes ends up in the generated js
  version: "__VERSION_HASH__",
  //
  construct() {
    return {
      characters: {},
      controllers: {}
    };
  },
  onEnteredTheGame(state, event) {
    const { characterID, controller: controllerAddress, newPosition } = event.args;
    const controller = controllerAddress.toLowerCase();
    const characterIDString = characterID.toString();
    const character = state.characters[characterIDString] || { controllers: {}, position: { x: 0, y: 0 } };
    character.controllers[controller] = 2 /* Owner */;
    character.position = bigIntIDToXY(newPosition);
    state.characters[characterIDString] = character;
    const controlledCharacters = state.controllers[controller] || [];
    controlledCharacters.push(characterIDString);
    console.log("onEnteredTheGame");
  },
  onLeftTheGame(state, event) {
    const { characterID, controller: controllerAddress, positionWhenLeaving } = event.args;
    controllerAddress.toLowerCase();
    const chracterIDString = characterID.toString();
    delete state.characters[chracterIDString];
  },
  onCommitmentMade(state, event) {
    const { characterID, commitmentHash, controller, epoch } = event.args;
    const characterIDString = characterID.toString();
    const character = state.characters[characterIDString];
    character.commitment = {
      epoch,
      commitmentHash
    };
  },
  onMoveRevealed(state, event) {
    const { characterID, controller: controllerAddress, epoch, newPosition, actions } = event.args;
    const chracterIDString = characterID.toString();
    const character = state.characters[chracterIDString];
    character.position = bigIntIDToXY(newPosition);
    character.commitment = void 0;
  }
};
const createProcessor = fromJSProcessor(() => GameIndexerProcessor);

export { ControllerType, createProcessor };
