import { AREA_SIZE, areaCoord } from 'template-game-common';
import artifacts from 'template-game-contracts/artifacts';
import { EVM } from '@ethereumjs/evm';
import { hexToBytes, Address, bytesToHex } from '@ethereumjs/util';
import { encodeFunctionData, decodeFunctionResult, decodeErrorResult, keccak256, encodePacked } from 'viem';

async function createEVMRunner(contracts) {
  const evm = await EVM.create();
  const contractNames = Object.keys(contracts);
  const contractAddresses = {};
  for (const contractName of contractNames) {
    const { bytecode, argsData } = contracts[contractName];
    const results = await evm.runCall({
      data: hexToBytes(bytecode + (argsData ? argsData.slice(2) : ""))
    });
    if (!results.createdAddress) {
      throw new Error(`could not deploy contract ${contractName}`);
    }
    const contractAddress = results.createdAddress?.toString();
    contractAddresses[contractName] = contractAddress;
  }
  async function runContract(name, data) {
    const contractAddress = contractAddresses[name];
    const results = await evm.runCall({
      to: Address.fromString(contractAddress),
      data: hexToBytes(data)
    });
    return bytesToHex(results.execResult.returnValue);
  }
  async function runCode(code) {
    return evm.runCode({
      code: hexToBytes(code),
      gasLimit: BigInt(65535)
    }).then((results) => {
      return bytesToHex(results.returnValue);
    });
  }
  return {
    runCode,
    runContract
  };
}

const Areas = [
  /*
   .   .   .   . | . | .   .   .   .   .   . |
                                              
   .   .   .   . | . | .   .   .   .   .   . |
                                              
   .   .   .   .   .   .   .   .   .   .   . |
                                              
   .   .   .   . | . | .   .   .   .   .   . |
                      ___ ___ ___     ___ ___ 
   .   .   .   . | .   .   . | .   .   .   . |
  ___ ___ ___ ___                             
   .   .   .   .   .   .   . | .   .   .   . |
      ___ ___     ___                         
   . | .   .   .   . | .   . | .   .   .   .  
                                              
   . | .   .   .   . | .   . | .   .   .   . |
                              ___ ___ ___ ___ 
   . | .   .   .   . | .   .   .   .   .   . |
  ___                                         
   .   .   .   .   . | .   .   .   .   .   . |
                                              
   .   .   .   .   . | .   .   .   .   .   . |
  ___ ___ ___ ___ ___ ___ ___     ___ ___ ___ 
  
  */
  {
    southWalls: [false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, true, true, true, false, true, true, true, true, true, true, false, false, false, false, false, false, false, false, true, true, false, true, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, true, true, true, true, true, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, true, true, true, true, true, true, true, false, true, true, true],
    eastWalls: [false, false, false, true, true, false, false, false, false, false, true, false, false, false, true, true, false, false, false, false, false, true, false, false, false, false, false, false, false, false, false, false, true, false, false, false, true, true, false, false, false, false, false, true, false, false, false, true, false, false, true, false, false, false, true, false, false, false, false, false, false, true, false, false, false, true, true, false, false, false, true, false, true, false, false, false, false, true, false, false, false, true, false, true, false, false, false, true, true, false, false, false, true, false, false, false, false, false, true, false, false, false, false, true, false, false, false, false, false, true, false, false, false, false, true, false, false, false, false, false, true]
  },
  /*
   .   .   .   .   . | .   .   .   .   . | . |
                                              
   .   .   .   .   . | .   .   .   .   . | .  
  ___     ___ ___ ___                         
   .   .   .   . | .   .   .   .   .   . | . |
                                              
   .   .   .   . | . | .   .   .   .   . | . |
                                              
   .   .   .   . | . | .   .   .   .   . | . |
                      ___ ___ ___ ___ ___     
   .   .   .   . | .   .   .   . | .   .   . |
  ___ ___     ___ ___ ___ ___                 
   .   . | . | .   .   .   . | .   .   .   . |
              ___ ___ ___ ___                 
   .   . | .   .   .   .   .   . | .   .   . |
          ___ ___     ___ ___ ___ ___     ___ 
   .   .   .   .   .   . | .   .   .   .   . |
                                              
   .   .   .   .   .   . | .   .   .   .   . |
                                              
   .   .   .   .   .   . | .   .   .   .   . |
  ___     ___ ___ ___ ___ ___ ___ ___ ___ ___ 
  
  */
  {
    southWalls: [false, false, false, false, false, false, false, false, false, false, false, true, false, true, true, true, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, true, true, true, true, true, false, true, true, false, true, true, true, true, false, false, false, false, false, false, false, true, true, true, true, false, false, false, false, false, false, true, true, false, true, true, true, true, false, true, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, true, false, true, true, true, true, true, true, true, true, true],
    eastWalls: [false, false, false, false, true, false, false, false, false, true, true, false, false, false, false, true, false, false, false, false, true, false, false, false, false, true, false, false, false, false, false, true, true, false, false, false, true, true, false, false, false, false, true, true, false, false, false, true, true, false, false, false, false, true, true, false, false, false, true, false, false, false, true, false, false, true, false, true, true, false, false, false, true, false, false, false, true, false, true, false, false, false, false, false, true, false, false, true, false, false, false, false, false, true, false, false, false, false, true, false, false, false, false, false, true, false, false, false, false, true, false, false, false, false, false, true, false, false, false, false, true]
  },
  /*
   .   .   .   .   .   .   .   .   .   .   . |
      ___ ___ ___ ___ ___ ___ ___ ___ ___     
   .   . | .   .   .   .   . | .   .   .   . |
  ___                             ___ ___ ___ 
   . | . | .   .   .   .   .   .   .   .   . |
                              ___ ___     ___ 
   . | . | .   .   .   .   . | .   .   .   . |
  ___     ___ ___     ___ ___                 
   .   .   .   . | .   .   . | .   .   .   . |
                                              
   .   .   .   . | .   .   . | .   .   .   . |
                              ___ ___ ___ ___ 
   .   .   .   . | .   .   .   .   .   .   . |
                              ___ ___ ___     
   .   .   .   . | .   .   . | .   .   . | . |
                                  ___ ___     
   .   .   .   . | .   .   . | . | .   .   . |
                                      ___ ___ 
   .   .   .   . | .   .   . | . | .   .   .  
                                  ___ ___ ___ 
   .   .   .   . | .   .   .   .   .   .   . |
  ___ ___ ___     ___ ___ ___ ___ ___ ___ ___ 
  
  */
  {
    southWalls: [false, true, true, true, true, true, true, true, true, true, false, true, false, false, false, false, false, false, false, true, true, true, false, false, false, false, false, false, false, true, true, false, true, true, false, true, true, false, true, true, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, true, true, true, true, false, false, false, false, false, false, false, true, true, true, false, false, false, false, false, false, false, false, false, true, true, false, false, false, false, false, false, false, false, false, false, true, true, false, false, false, false, false, false, false, false, true, true, true, true, true, true, false, true, true, true, true, true, true, true],
    eastWalls: [false, false, false, false, false, false, false, false, false, false, true, false, true, false, false, false, false, true, false, false, false, true, true, true, false, false, false, false, false, false, false, false, true, true, true, false, false, false, false, true, false, false, false, true, false, false, false, true, false, false, true, false, false, false, true, false, false, false, true, false, false, true, false, false, false, true, false, false, false, true, false, false, false, false, false, false, true, false, false, false, true, false, false, true, false, false, true, true, false, false, false, true, false, false, true, true, false, false, true, false, false, false, true, false, false, true, true, false, false, false, false, false, false, true, false, false, false, false, false, false, true]
  },
  /*
   .   .   .   .   . | . | .   .   .   .   . |
                                              
   .   .   .   .   . | . | .   .   .   .   . |
                                              
   .   .   .   .   . | . | .   .   .   .   . |
                                              
   .   .   .   .   . | . | .   .   .   .   . |
                                              
   .   .   .   .   . | . | .   .   .   .   . |
  ___     ___ ___ ___     ___     ___ ___ ___ 
   .   .   .   .   .   .   .   .   .   .   .  
  ___ ___ ___     ___     ___ ___ ___     ___ 
   .   .   .   .   . | . | .   .   .   .   . |
                                              
   .   .   .   .   . | . | .   .   .   .   . |
                                              
   .   .   .   .   . | . | .   .   .   .   . |
                                              
   .   .   .   .   . | . | .   .   .   .   . |
                                              
   .   .   .   .   . | . | .   .   .   .   . |
  ___ ___ ___ ___ ___     ___ ___ ___ ___ ___ 
  
  */
  {
    southWalls: [false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, true, false, true, true, true, false, true, false, true, true, true, true, true, true, false, true, false, true, true, true, false, true, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, true, true, true, true, true, false, true, true, true, true, true],
    eastWalls: [false, false, false, false, true, true, false, false, false, false, true, false, false, false, false, true, true, false, false, false, false, true, false, false, false, false, true, true, false, false, false, false, true, false, false, false, false, true, true, false, false, false, false, true, false, false, false, false, true, true, false, false, false, false, true, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, true, true, false, false, false, false, true, false, false, false, false, true, true, false, false, false, false, true, false, false, false, false, true, true, false, false, false, false, true, false, false, false, false, true, true, false, false, false, false, true, false, false, false, false, true, true, false, false, false, false, true]
  },
  /*
   .   .   .   .   .   .   .   .   .   .   . |
                                              
   .   .   .   .   .   .   .   .   .   .   . |
                                              
   .   .   .   .   .   .   .   .   .   .   . |
                                              
   .   .   .   .   .   .   .   .   .   .   . |
                                              
   .   .   .   .   .   .   .   .   .   .   . |
                                              
   .   .   .   .   .   .   .   .   .   .   .  
                                              
   .   .   .   .   .   .   .   .   .   .   . |
                                              
   .   .   .   .   .   .   .   .   .   .   . |
                                              
   .   .   .   .   .   .   .   .   .   .   . |
                                              
   .   .   .   .   .   .   .   .   .   .   . |
                                              
   .   .   .   .   .   .   .   .   .   .   . |
  ___ ___ ___ ___ ___     ___ ___ ___ ___ ___ 
  
  */
  {
    southWalls: [false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, true, true, true, true, true, false, true, true, true, true, true],
    eastWalls: [false, false, false, false, false, false, false, false, false, false, true, false, false, false, false, false, false, false, false, false, false, true, false, false, false, false, false, false, false, false, false, false, true, false, false, false, false, false, false, false, false, false, false, true, false, false, false, false, false, false, false, false, false, false, true, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, true, false, false, false, false, false, false, false, false, false, false, true, false, false, false, false, false, false, false, false, false, false, true, false, false, false, false, false, false, false, false, false, false, true, false, false, false, false, false, false, false, false, false, false, true]
  }
];

class EVMGame {
  constructor() {
    this.evmPromise = createEVMRunner({
      GameUtils: {
        bytecode: artifacts.GameUtils.bytecode
      },
      GameReveal: {
        bytecode: artifacts.GameReveal.bytecode
      }
    });
  }
  async areaAt(x, y) {
    const evm = await this.evmPromise;
    const result = await evm.runContract(
      "GameUtils",
      encodeFunctionData({
        abi: artifacts.GameUtils.abi,
        functionName: "areaAt",
        args: [x, y]
      })
    );
    let data;
    try {
      data = decodeFunctionResult({
        abi: artifacts.GameUtils.abi,
        functionName: "areaAt",
        data: result
      });
    } catch (err) {
      const error = decodeErrorResult({
        abi: artifacts.GameUtils.abi,
        data: result
      });
      throw error;
    }
    const southWalls = [];
    const eastWalls = [];
    let c = 127n;
    for (let iy = 0; iy < AREA_SIZE; iy++) {
      for (let ix = 0; ix < AREA_SIZE; ix++) {
        southWalls.push((data.southWalls >> c & 1n) == 1n);
        eastWalls.push((data.eastWalls >> c & 1n) == 1n);
        c--;
      }
    }
    return {
      x,
      y,
      eastWalls,
      southWalls
    };
  }
  getArea(x, y) {
    const areaX = areaCoord(x);
    const areaY = areaCoord(y);
    const areaHash = keccak256(encodePacked(["int32", "int32"], [areaX, areaY]));
    const areaIndex = Number(BigInt(areaHash) % 5n);
    return { ...Areas[areaIndex], x: areaX, y: areaY };
  }
  async stepChanges(stateChanges, context, action) {
    const evm = await this.evmPromise;
    const result = await evm.runContract(
      "GameReveal",
      encodeFunctionData({
        abi: artifacts.GameReveal.abi,
        functionName: "stepChanges",
        args: [stateChanges, context, action, true]
      })
    );
    try {
      return decodeFunctionResult({
        abi: artifacts.GameReveal.abi,
        functionName: "stepChanges",
        data: result
      });
    } catch (err) {
      const error = decodeErrorResult({
        abi: artifacts.GameReveal.abi,
        data: result
      });
      throw error;
    }
  }
  async initialStateChanges(context) {
    const evm = await this.evmPromise;
    const result = await evm.runContract(
      "GameReveal",
      encodeFunctionData({
        abi: artifacts.GameReveal.abi,
        functionName: "initialStateChanges",
        args: [context]
      })
    );
    try {
      return decodeFunctionResult({
        abi: artifacts.GameReveal.abi,
        functionName: "initialStateChanges",
        data: result
      });
    } catch (err) {
      const error = decodeErrorResult({
        abi: artifacts.GameReveal.abi,
        data: result
      });
      throw error;
    }
  }
}

export { EVMGame };
